<template>
  <v-form
    ref="form"
    v-model="valid"
    @input="$emit('valid', $event)"
    lazy-validation
  >
    <v-row>
      <v-col
        cols="12"
      >
        <v-row>
          <v-col
            cols="12"
          >
            <v-text-field
              label="Name *"
              dense
              v-model="connections.name"
              required
              :rules="fieldRequireName"
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            sm="6"
          >
            <v-text-field
              label="Host *"
              dense
              v-model="connections.host"
              required
              :rules="[v => !!v || 'field is required']"
            ></v-text-field>
            <!-- <div class="subtitle-1 text--secondary">Host *</div> -->
          </v-col>
          <v-col
            cols="12"
            sm="6"
          >
            <v-text-field
              label="Port *"
              dense
              v-model.number="connections.port"
              required
              :rules="[v => !!v || 'field is required', v => /^()([1-9]|[1-5]?[0-9]{2,4}|6[1-4][0-9]{3}|65[1-4][0-9]{2}|655[1-2][0-9]|6553[1-5])$/.test(v) || 'port must be valid']"
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            sm="6"
          >
            <v-text-field
              label="Username *"
              dense
              v-model="connections.user"
              required
              :rules="[v => !!v || 'field is required']"
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            sm="6"
          >
            <v-text-field
              label="Password *"
              :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
              :type="showPass ? 'text' : 'password'"
              dense
              v-model="connections.password"
              required
              :rules="[v => !!v || 'field is required']"
              @click:append="showPass = !showPass"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <!-- <small>*indicates required field</small> -->
  </v-form>
</template>

<script>
import { mapGetters } from 'vuex'
import api from '@/services/nest'
// import cleanDeep from 'clean-deep'
import mqttTemplate from '@/template/mqttTemplate.json'

export default {
  name: 'MqttConnectionsForm',
  model: {
    prop: 'connections',
    event: 'input'
  },
  components: {
  },
  props: {
    connections: {
      type: Object,
      default () {
        return JSON.parse(JSON.stringify(mqttTemplate.connections))
      },
      required: false
    },
    connectionsList: {
      type: Array,
      default: () => [],
      required: false
    },
    isCreated: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      valid: true,
      showPass: false
    }
  },
  methods: {
    resetValidateForm: function () {
      this.$refs.form.resetValidation()
    },
    createConnection: function () {
      console.log('create connection')
      return api.post(`/api/services/${this.fiwareService}/connections`, this.connections, null, null, this.$store.state.tokenRPT).then((response) => {
        return response.json()
      })
    }
  },
  computed: {
    ...mapGetters(['fiwareService']),
    fieldRequire: function () {
      return v => !!v || 'field is required'
    },
    fieldRequireHost: function () {
      const validIpAddressRegex = /^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/g
      const validHostnameRegex = /^(([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]).)*([A-Za-z0-9]|[A-Za-z0-9][A-Za-z0-9-]*[A-Za-z0-9])$/g
      return [
        v => !!v || 'field is required',
        v => (validIpAddressRegex.test(v) || validHostnameRegex.test(v)) || 'filed is invalid ex. 1.1.1.1 or www.example.com'
      ]
    },
    fieldRequireName: function () {
      // return this.connectionsList.map((connList) => connList._source.name)
      return [
        v => !!v || 'field is required',
        v => this.connectionsList.map((connList) => connList._source.name).indexOf(!!v) === -1 || 'fild is duplicate name'
      ]
    }
  },
  watch: {
  },
  created () {
  },
  mounted () {
    this.$emit('ready')
  }
}
</script>
